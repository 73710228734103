<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">在國外聯絡資訊</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper member form" v-if="ApplyData">
      <a href="#AC" class="accesskey accesskey_c" title="中央內容區塊" accesskey="C" name="C">:::</a>
      <div class="container">
        <h2>在國外聯絡資訊</h2>
        <div class="box show">
          <div class="contact_box">
            <h2>聯絡資訊</h2>
            <div class="form-group">
              <input type="date" class="form-control" v-model="Apply.C_TimeToGoAbroad" aria-describedby=""
                placeholder="請填寫出國時間">
              <label for=""><span>出國時間</span></label>
            </div>
            <div class="form-group">
              <input type="date" class="form-control" v-model="Apply.C_ReturnTime" aria-describedby=""
                placeholder="請填寫回國時間">
              <label for=""><span>回國時間</span></label>
            </div>
            <div class="form-group ">
              <input type="text" class="form-control" v-model="Apply.C_Address" aria-describedby=""
                placeholder="請填寫在國外聯絡地址">
              <label for=""><span>在國外聯絡地址</span></label>
            </div>
            <div class="form-group ">
              <input type="text" class="form-control" v-model="Apply.C_Phone" aria-describedby=""
                placeholder="請填寫在國外聯絡電話">
              <label for=""><span>在國外聯絡電話</span></label>
            </div>
            <h2>社群帳號</h2>
            <div class="form-group ">
              <input type="text" class="form-control" v-model="Apply.C_LINE" aria-describedby="" placeholder="請填寫LINE帳號">
              <label for=""><span>LINE帳號</span></label>
            </div>
            <div class="form-group ">
              <input type="text" class="form-control" v-model="Apply.C_WeChat" aria-describedby=""
                placeholder="請填寫WeChat帳號">
              <label for=""><span>WeChat帳號</span></label>
            </div>
            <div class="form-group ">
              <input type="text" class="form-control" v-model="Apply.C_FB" aria-describedby="" placeholder="請填寫FB帳號">
              <label for=""><span>FB帳號</span></label>
            </div>
            <div class="btn">
              <button @click="send()">儲存</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "ForeignContact",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      ApplyData: null,
      Apply: null,
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "Login",
          });
        },
      });
      return;
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/ContactAndReport",
        {
          params: { AId: this.$route.params.AId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.ApplyData = response.data;
        if (this.ApplyData.Error) {
          let that = this;
          Swal.fire({
            title: "錯誤",
            text: this.ApplyData.Error,
            icon: "error",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              that.$router.push({
                name: "Home",
              });
            },
          });
        } else {
          this.Apply = this.ApplyData.Apply;
          if (this.Apply.C_TimeToGoAbroad) {
            this.Apply.C_TimeToGoAbroad = this.convertDatetimeString(
              this.Apply.C_TimeToGoAbroad,
              "YYYY-MM-DD"
            );
          } else {
            this.Apply.C_TimeToGoAbroad = "";
          }
          if (this.Apply.C_ReturnTime) {
            this.Apply.C_ReturnTime = this.convertDatetimeString(
              this.Apply.C_ReturnTime,
              "YYYY-MM-DD"
            );
          } else {
            this.Apply.C_ReturnTime = "";
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    checkFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 10485760) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過10MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (id == "R_Experience") {
              that.Apply.R_Experience = reader.result;
            } else if (id == "R_Photo") {
              that.Apply.R_Photo = reader.result;
            }
            $("#" + id + "_data").val(reader.result);
            $("#" + id + "_name").val(file.name);
            //$("#" + id).val('');
            Swal.close();
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理檔案過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    send() {
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 儲存檔案名稱
      var AttachmentNames = "";
      $("[data-namgtag='1']").each(function () {
        if (AttachmentNames.length > 0) { AttachmentNames += ", "; } else { AttachmentNames += "{"; }
        var input = $(this);
        AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
      });
      if (AttachmentNames == "") { AttachmentNames = "{"; }
      AttachmentNames += "}";
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ContactAndReport",
        type: "post",
        data: {
          ApplyJson: JSON.stringify(this.Apply),
          AttachmentNames: AttachmentNames,
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "更新成功",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                $('html,body').animate({ scrollTop: 0 }, 1000);
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    convertDatetimeString(time, format) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format(format);
    },
  },
};
</script>

