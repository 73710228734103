<template>
  <div>

    <div class="wrapper exchange_student_form_1 form out_exchange_form_1 out_exchange_form_3 out_exchange_form_4"
      v-if="Apply">
      <a href="#AC" class="accesskey accesskey_c" title="中央內容區塊" accesskey="C" name="C">:::</a>
      <section class="container">
        <div class="">
          <template v-if="Apply.ApplyType == 1 || Apply.ApplyType == 2">
            <h2 v-if="Apply.ApplyType == 1">國立臺灣藝術大學外籍學位生甄選申請表</h2>
            <h2 v-if="Apply.ApplyType == 2">國立臺灣藝術大學外籍交換生甄選申請表</h2>
            <!-- 資料區 -->
            <div class="box">
              <h2>個人資料</h2>
              <div class="print_box">
                <div class="file_box">
                  <div class="custom_file">
                    <img id="preview_progressbarTW_img" :src="Apply.Photo" />
                  </div>
                </div>
              </div>
              <div class="personal_box print_box">
                <div class="form-group">
                  <p class="form-control">{{ Apply.Name }}</p>
                  <label for=""><span><span class="required">*</span>中文姓名</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.PassportNumber }}</p>
                  <label for=""><span><span class="required">*</span>護照號碼</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.Phone }}</p>
                  <label for=""><span><span class="required">*</span>電話</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.Nationality }}</p>
                  <label for=""><span><span class="required">*</span>國籍</span></label>
                </div>
                <!-- <div class="form-group">
                  <p class="form-control">{{ Apply.T3_Department }}</p>
                  <label for=""><span><span class="required">*</span>就讀系所</span></label>
                </div> -->
              </div>
              <div class="print_box">
                <div class="form-group">
                  <p class="form-control">{{ Apply.Name_EN }}</p>
                  <label for=""><span><span class="required">*</span>英文姓名</span></label>
                </div>
                <div class="dd">
                  <div class="form-group">
                    <p class="form-control">{{ Apply.Birthday }}</p>
                    <label for=""><span><span class="required">*</span>出生年月日</span></label>
                  </div>
                  <div class="form-group">
                    <p class="form-control" v-if="Apply.Gender == 1">男</p>
                    <p class="form-control" v-if="Apply.Gender == 2">女</p>
                    <p class="form-control" v-if="Apply.Gender == 3">非二元</p>
                    <p class="form-control" v-if="Apply.Gender == 4">其他</p>
                    <label for=""><span><span class="required">*</span>性別</span> </label>
                  </div>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.Email }}</p>
                  <label for=""><span><span class="required">*</span>電子郵件</span></label>
                </div>
                <div class="form-group student">
                  <p class="form-control">{{ Apply.Address }}</p>
                  <label for=""><span><span class="required">*</span>居住地址</span></label>
                </div>
                <!-- <div class="form-group">
                  <p class="form-control">{{ Apply.T3_MainDepartment }}</p>
                  <label for=""><span><span class="required">*</span>主修/專攻</span></label>
                </div> -->
              </div>
              <div class="form-group full">
                <p class="form-control">{{ Apply.Program }}</p>
                <label for=""><span><span class="required">*</span>申請系所</span></label>
              </div>
            </div>
            <div class="box">
              <h2>緊急聯絡人</h2>
              <div class="form-group" style="flex-basis: 50%;">
                <p class="form-control">{{ Apply.EmergencyName }}</p>
                <label for=""><span><span class="required">*</span>姓名</span></label>
              </div>
              <div class="form-group" style="flex-basis: 50%;">
                <p class="form-control">{{ Apply.EmergencyRelation }}</p>
                <label for=""><span><span class="required">*</span>關係</span></label>
              </div>
              <div class="form-group" style="flex-basis: 50%;">
                <p class="form-control">{{ Apply.EmergencyPhone }}</p>
                <label for=""><span><span class="required">*</span>住家電話</span></label>
              </div>
              <div class="form-group" style="flex-basis: 50%;">
                <p class="form-control">{{ Apply.EmergencyEmail }}</p>
                <label for=""><span>電子郵件</span></label>
              </div>
            </div>
            <template v-if="Apply.ApplyType == 1">
              <div class="box">
                <h2>家庭資料</h2>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_ParentsFatherName }}</p>
                  <label for=""><span><span class="required">*</span>父親姓名</span></label>
                </div>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_ParentsFatherNationality }}</p>
                  <label for=""><span><span class="required">*</span>父親國籍</span></label>
                </div>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_ParentsMotherName }}</p>
                  <label for=""><span><span class="required">*</span>母親姓名</span></label>
                </div>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_ParentsMotherNationality }}</p>
                  <label for=""><span><span class="required">*</span>母親國籍</span></label>
                </div>
              </div>
              <div class="box">
                <h2>最高學歷</h2>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_EducationLeve }}</p>
                  <label for=""><span><span class="required">*</span>學歷</span></label>
                </div>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_CountryOfSchool }}</p>
                  <label for=""><span><span class="required">*</span>學校國家</span></label>
                </div>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_SchoolName }}</p>
                  <label for=""><span><span class="required">*</span>學校名稱(中文)</span></label>
                </div>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_SchoolName_EN }}</p>
                  <label for=""><span><span class="required">*</span>學校名稱(英文)</span></label>
                </div>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_StudyDurationStart }}至{{ Apply.T1_StudyDurationStart }}</p>
                  <label for=""><span><span class="required">*</span>就學期間</span></label>
                </div>
                <div class="form-group" style="flex-basis: 50%;">
                  <p class="form-control">{{ Apply.T1_Department }}</p>
                  <label for=""><span><span class="required">*</span>系所/主修</span></label>
                </div>
              </div>
            </template>
            <template v-if="Apply.ApplyType == 2">
              <div class="box">
                <h2>在學狀況</h2>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T2_SchoolName }}</p>
                  <label for=""><span><span class="required">*</span>目前就讀學校</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T2_Department }}</p>
                  <label for=""><span><span class="required">*</span>系所</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T2_YearInSchool }}</p>
                  <label for=""><span><span class="required">*</span>年級</span></label>
                </div>
              </div>
              <div class="box">
                <h2>交換計畫</h2>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T2_ExchangeDepartment }}</p>
                  <label for=""><span><span class="required">*</span>系所</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T2_ExchangeProgram }}</p>
                  <label for=""><span><span class="required">*</span>學制</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T2_ExchangePeriod }}</p>
                  <label for=""><span><span class="required">*</span>交換時間</span></label>
                </div>
              </div>
              <div class="box">
                <h2>語言能力-中文</h2>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_R == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_R == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_R == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_R == 1">差</p>
                  <label for=""><span><span class="required">*</span>讀</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_W == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_W == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_W == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_W == 1">差</p>
                  <label for=""><span><span class="required">*</span>寫</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_L == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_L == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_L == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_L == 1">差</p>
                  <label for=""><span><span class="required">*</span>聽</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_S == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_S == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_S == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_CN_S == 1">差</p>
                  <label for=""><span><span class="required">*</span>說</span></label>
                </div>
              </div>
              <div class="box">
                <h2>語言能力-英文</h2>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_R == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_R == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_R == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_R == 1">差</p>
                  <label for=""><span><span class="required">*</span>讀</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_W == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_W == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_W == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_W == 1">差</p>
                  <label for=""><span><span class="required">*</span>寫</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_L == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_L == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_L == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_L == 1">差</p>
                  <label for=""><span><span class="required">*</span>聽</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_S == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_S == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_S == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_EN_S == 1">差</p>
                  <label for=""><span><span class="required">*</span>說</span></label>
                </div>
              </div>
              <div class="box" v-if="Apply.T2_LangSkill_O1_Name">
                <h2>語言能力-{{ Apply.T2_LangSkill_O1_Name }}</h2>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_R == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_R == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_R == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_R == 1">差</p>
                  <label for=""><span><span class="required">*</span>讀</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_W == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_W == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_W == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_W == 1">差</p>
                  <label for=""><span><span class="required">*</span>寫</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_L == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_L == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_L == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_L == 1">差</p>
                  <label for=""><span><span class="required">*</span>聽</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_S == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_S == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_S == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O1_S == 1">差</p>
                  <label for=""><span><span class="required">*</span>說</span></label>
                </div>
              </div>
              <div class="box" v-if="Apply.T2_LangSkill_O2_Name">
                <h2>語言能力-{{ Apply.T2_LangSkill_O2_Name }}</h2>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_R == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_R == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_R == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_R == 1">差</p>
                  <label for=""><span><span class="required">*</span>讀</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_W == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_W == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_W == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_W == 1">差</p>
                  <label for=""><span><span class="required">*</span>寫</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_L == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_L == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_L == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_L == 1">差</p>
                  <label for=""><span><span class="required">*</span>聽</span></label>
                </div>
                <div class="form-group" style="flex-basis: 25%;">
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_S == 4">優</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_S == 3">良</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_S == 2">可</p>
                  <p class="form-control" v-if="Apply.T2_LangSkill_O2_S == 1">差</p>
                  <label for=""><span><span class="required">*</span>說</span></label>
                </div>
              </div>
            </template>
            <div class="box">
              <ul class="list">
                <li>
                  <p class="title">系所簽核欄(簽核序位1)</p>
                  <p>承辦人</p>
                  <p>單位主管</p>
                </li>
                <li>
                  <p class="title">院簽核欄(簽核序位2)</p>
                  <p>承辦人</p>
                  <p>院長</p>
                </li>
                <li>
                  <p class="title">會辦教務處註冊組(簽核序位3)</p>
                  <p class="small">大四、碩二、博三(含)以上者</p>
                  <p>承辦人</p>
                  <p>組長</p>
                </li>
              </ul>
            </div>
            <div class="nn" style='page-break-before: always'></div>
            <h2 v-if="Apply.ApplyType == 1">國立臺灣藝術大學外籍學位生甄選證件影本資料表</h2>
            <h2 v-if="Apply.ApplyType == 2">國立臺灣藝術大學外籍交換生甄選證件影本資料表</h2>
            <!-- 資料區 -->
            <div class="box">
              <h2>身份證正反面/居留證正反面/多次入出境證正反面</h2>
              <div class="blank_box">
              </div>
            </div>
            <div class="box">
              <h2>學生證正反面[務必加蓋本學期之註冊章]</h2>
              <div class="blank_box">
              </div>
            </div>
          </template>
          <template v-if="Apply.ApplyType == 3">
            <h2>
              國立臺灣藝術大學出國交換生甄選申請表
            </h2>
            <div class="box">
              <h2>個人資料</h2>
              <div class="print_box">
                <div class="file_box">
                  <div class="custom_file">
                    <img id="preview_progressbarTW_img" :src="Apply.Photo" />
                  </div>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_College }}</p>
                  <label for=""><span><span class="required">*</span>就讀學院</span></label>
                </div>
              </div>
              <div class="personal_box print_box">
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_Name }}</p>
                  <label for=""><span><span class="required">*</span>中文姓名</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_IdentityNumber }}</p>
                  <label for=""><span><span class="required">*</span>身分證號</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_Phone }}</p>
                  <label for=""><span><span class="required">*</span>手機</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_EduLevel }}</p>
                  <label for=""><span><span class="required">*</span>就讀學位別</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_Department }}</p>
                  <label for=""><span><span class="required">*</span>就讀系所</span></label>
                </div>
              </div>
              <div class="print_box">
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_Name_EN_First }} {{ Apply.T3_Name_EN_Last }}</p>
                  <label for=""><span><span class="required">*</span>護照英文姓名</span></label>
                </div>
                <div class="dd">
                  <div class="form-group">
                    <p class="form-control">{{ Apply.T3_Birthday }}</p>
                    <label for=""><span><span class="required">*</span>出生年月日</span></label>
                  </div>
                  <div class="form-group">
                    <p class="form-control" v-if="Apply.T3_Gender == 1">男</p>
                    <p class="form-control" v-if="Apply.T3_Gender == 2">女</p>
                    <p class="form-control" v-if="Apply.T3_Gender == 3">非二元</p>
                    <p class="form-control" v-if="Apply.T3_Gender == 4">其他</p>
                    <label for=""><span><span class="required">*</span>性別</span> </label>
                  </div>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_Email }}</p>
                  <label for=""><span><span class="required">*</span>電子郵件</span></label>
                </div>
                <div class="form-group student">
                  <p class="form-control">{{ Apply.T3_StudentNumber }}</p>
                  <label for="">
                    <span>
                      <span class="required">*</span>
                      學號
                    </span>
                    <!-- <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" id="checkbox_group2">
                    <label class="form-check-label" for="checkbox_group2">非台灣本地生</label>
                  </div> -->
                  </label>
                </div>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_MainDepartment }}</p>
                  <label for=""><span><span class="required">*</span>主修/專攻</span></label>
                </div>
              </div>
            </div>
            <div class="box">
              <h2>緊急聯絡人</h2>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_EmergencyName }}</p>
                <label for=""><span><span class="required">*</span>姓名</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_EmergencyName_EN }}</p>
                <label for=""><span><span class="required">*</span>護照英文姓名</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_EmergencyRelation }}</p>
                <label for=""><span><span class="required">*</span>關係</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_EmergencyEmail }}</p>
                <label for=""><span>電子郵件</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_EmergencyHomePhone }}</p>
                <label for=""><span><span class="required">*</span>住家電話</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_EmergencyPhone }}</p>
                <label for=""><span><span class="required">*</span>手機</span></label>
              </div>
            </div>
            <div class="box">
              <h2>申請資料</h2>
              <!-- <div class="form-group">
              <p class="form-control">申請國家</p>
              <label for=""><span><span class="required">*</span>申請國家</span></label>
            </div> -->
              <div class="form-group full">
                <p class="form-control">{{ Apply.T3_ApplySchool }}</p>
                <label for=""><span><span class="required">*</span>申請學校</span></label>
              </div>
              <div class="form-group full">
                <p class="form-control">{{ Apply.T3_ApplyDepartment }} {{ Apply.T3_ApplyDepartment_EN }}</p>
                <label for=""><span><span class="required">*</span>申請系所</span></label>
              </div>
              <div class="form-group basis_2">
                <p class="form-control">{{ Apply.T3_StudyDurationStart }}至{{ Apply.T3_StudyDurationEnd }}上學期</p>
                <label for=""><span><span class="required">*</span>申請研修期間</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_BeSoldier }}</p>
                <label for=""><span><span class="required">*</span>是否需服兵役</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_ApplyProgram }}</p>
                <label for=""><span><span class="required">*</span>申請學制</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_ApplyGrade }}</p>
                <label for=""><span><span class="required">*</span>申請年級</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_Award }}</p>
                <label for=""><span><span class="required">*</span>競賽得獎證明</span></label>
              </div>
              <div class="score_box">
                <div class="title">
                  <h3 class="">語檢成績(有效期限內)</h3>
                  <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" id="checkbox_group1"
                      v-model="Apply.T3_TestScore_NoNeed">
                    <label class="form-check-label" for="checkbox_group1">不需檢附語檢成績</label>
                  </div>
                </div>
                <hr>
                <div class="form-group" v-show="!Apply.T3_TestScore_NoNeed">
                  <p class="form-control">{{ Apply.T3_TestName }}</p>
                  <label for=""><span><span class="required">*</span>測驗名稱</span></label>
                </div>
                <div class="form-group" v-show="!Apply.T3_TestScore_NoNeed">
                  <p class="form-control">{{ Apply.T3_TestScore }}</p>
                  <label for=""><span><span class="required">*</span>分數</span></label>
                </div>
                <div class="form-group" v-show="!Apply.T3_TestScore_NoNeed">
                  <p class="form-control">{{ Apply.T3_TestLevel }}</p>
                  <label for=""><span><span class="required">*</span>等級</span></label>
                </div>
                <div class="form-group" v-show="!Apply.T3_TestScore_NoNeed">
                  <p class="form-control">{{ Apply.T3_TestDay }}</p>
                  <label for=""><span><span class="required">*</span>考試日期</span></label>
                </div>
              </div>
              <div class="score_box">
                <div class="title">
                  <h3 class="">學業成績</h3>
                </div>
                <hr>
                <div class="form-group">
                  <p class="form-control">{{ Apply.T3_AverageScore }}</p>
                  <label for=""><span><span class="required">*</span>歷年學業平均成績（各學期加總平均）</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control" v-if="!Apply.T3_Ranking_NoNeed">{{ Apply.T3_Ranking }}</p>
                  <p class="form-control" v-if="Apply.T3_Ranking_NoNeed">- 我是研究生免填 -</p>
                  <label for=""><span><span class="required">*</span>歷年班排名/人數</span></label>
                </div>
                <div class="form-group">
                  <p class="form-control" v-if="!Apply.T3_GPA_NoNeed">{{ Apply.T3_GPA }}</p>
                  <p class="form-control" v-if="Apply.T3_GPA_NoNeed">- 我不用提供歷年英文成績單 -</p>
                  <label for=""><span><span class="required">*</span>歷年學業GPA成績</span></label>
                </div>
              </div>
              <div class="notice">
                <p>*本表資料填寫不全者，視為資格不符</p>
              </div>
              <div class="text" style="margin-top: 40px;">
                <p>本人保證以上所填皆屬事實
                  <br>
                  申請人親筆簽名
                </p>
                <p></p>
                <p>填寫日期<span>年</span><span>月</span><span>日</span></p>
              </div>
            </div>
            <div class="box">
              <ul class="list">
                <li>
                  <p class="title">系所簽核欄(簽核序位1)</p>
                  <p>承辦人</p>
                  <p>單位主管</p>
                </li>
                <li>
                  <p class="title">院簽核欄(簽核序位2)</p>
                  <p>承辦人</p>
                  <p>院長</p>
                </li>
                <li>
                  <p class="title">會辦教務處註冊組(簽核序位3)</p>
                  <p class="small">大四、碩二、博三(含)以上者</p>
                  <p>承辦人</p>
                  <p>組長</p>
                </li>
              </ul>
            </div>
            <div class="nn" style='page-break-before: always'></div>
            <h2>國立臺灣藝術大學交換學生甄選證件影本資料表</h2>
            <div class="box">
              <h2>學生基本資料</h2>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_Name }}</p>
                <label for=""><span><span class="required">*</span>中文姓名</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_Birthday }}</p>
                <label for=""><span><span class="required">*</span>出生年月日</span></label>
              </div>
              <div class="form-group">
                <p class="form-control" v-if="Apply.T3_Gender == 1">男</p>
                <p class="form-control" v-if="Apply.T3_Gender == 2">女</p>
                <p class="form-control" v-if="Apply.T3_Gender == 3">非二元</p>
                <p class="form-control" v-if="Apply.T3_Gender == 4">其他</p>
                <label for=""><span><span class="required">*</span>性別</span> </label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_College }}</p>
                <label for=""><span><span class="required">*</span>就讀系所</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_Department }}</p>
                <label for=""><span><span class="required">*</span>就讀學制</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_StudentNumber }}</p>
                <label for="">
                  <span>
                    <span class="required">*</span>
                    學號
                  </span>
                </label>
              </div>
            </div>
            <div class="box">
              <h2>聯絡資料</h2>
              <div class="form-group full">
                <p class="form-control"> </p>
                <label for=""><span><span class="required">*</span>通訊地址</span></label>
              </div>
              <div class="form-group">
                <p class="form-control"></p>
                <label for=""><span><span class="required">*</span>家裡電話</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_Phone }}</p>
                <label for=""><span><span class="required">*</span>手機</span></label>
              </div>
              <div class="form-group">
                <p class="form-control">{{ Apply.T3_Email }}</p>
                <label for=""><span><span class="required">*</span>電子郵件</span></label>
              </div>
            </div>
            <div class="box">
              <h2>身份證正反面/居留證正反面/多次入出境證正反面</h2>
              <div class="blank_box">
              </div>
            </div>
            <div class="box">
              <h2>學生證正反面[務必加蓋本學期之註冊章]</h2>
              <div class="blank_box">
              </div>
            </div>
          </template>

          <div class="btn_box print_btn">
            <button onclick="window.print()">列印</button>
          </div>
          <!-- <hr>
          <div class="btn_box print_btn">
            <div class="download">
              <p>國立臺灣藝術大學交換學生繳交資料確認明細表</p>
              <button>下載列印</button>
            </div>
          </div> -->
        </div>
      </section>
    </div>

  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

import Select2 from 'v-select2-component';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);

export default {
  i18n,
  name: "ApplyPrintForManager",
  components: {
    Select2
  },
  data() {
    return {
      ApplyData: null,
      Apply: null,
      local: "zh_tw",
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/ApplyPrintForManager",
        {
          params: { AId: this.$route.params.AId },
        }
      )
      .then((response) => {
        this.ApplyData = response.data;
        if (this.ApplyData.Error) {
          let that = this;
          Swal.fire({
            title: "錯誤",
            text: this.ApplyData.Error,
            icon: "error",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              that.$router.push({
                name: "Home",
              });
            },
          });
        } else {
          this.Apply = this.ApplyData.Apply;
          if (this.Apply.Birthday) {
            this.Apply.Birthday = this.convertDatetimeString(
              this.Apply.Birthday,
              "YYYY-MM-DD"
            );
          } else {
            this.Apply.Birthday = "";
          }
          if (this.Apply.T1_StudyDurationStart) {
            this.Apply.T1_StudyDurationStart = this.convertDatetimeString(
              this.Apply.T1_StudyDurationStart,
              "YYYY-MM"
            );
          } else {
            this.Apply.T1_StudyDurationStart = "";
          }
          if (this.Apply.T1_StudyDurationEnd) {
            this.Apply.T1_StudyDurationEnd = this.convertDatetimeString(
              this.Apply.T1_StudyDurationEnd,
              "YYYY-MM"
            );
          } else {
            this.Apply.T1_StudyDurationEnd = "";
          }
          if (this.Apply.T3_Birthday) {
            this.Apply.T3_Birthday = this.convertDatetimeString(
              this.Apply.T3_Birthday,
              "YYYY-MM-DD"
            );
          } else {
            this.Apply.T3_Birthday = "";
          }
          if (this.Apply.T3_StudyDurationStart) {
            this.Apply.T3_StudyDurationStart = this.convertDatetimeString(
              this.Apply.T3_StudyDurationStart,
              "YYYY-MM"
            );
          } else {
            this.Apply.T3_StudyDurationStart = "";
          }
          if (this.Apply.T3_StudyDurationEnd) {
            this.Apply.T3_StudyDurationEnd = this.convertDatetimeString(
              this.Apply.T3_StudyDurationEnd,
              "YYYY-MM"
            );
          } else {
            this.Apply.T3_StudyDurationEnd = "";
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    convertDatetimeString(time, format) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format(format);
    },
  },
};
</script>
