<template>
  <div>
    <Head />

    <nav class="breadcrumb_box" v-if="SearchData">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ SearchData.Keyword }}</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper list_1" v-if="SearchData">
      <a href="#AC" class="accesskey accesskey_c" title="中央內容區塊" accesskey="C" name="C">:::</a>
      <section class="news">
        <div class="bg">
          <div class="container">
            <div class="title_box">
              <img src="img/title_icon1.svg" alt="">
              <h2>{{ SearchData.Keyword }} 的搜尋結果</h2>
            </div>
            <ul class="list show">
              <li v-for="Post in Posts" :key="Post.PId">
                <a href="javascript:void(0)" @click="gotoPost(Post)">
                  <div class="tag_box">
                    <img src="img/news_hot.svg" alt="" v-if="Post.IsTop">
                  </div>
                  <div class="text_box">
                    <p class="time">{{ Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                    <p>{{ Post.Title }}</p>
                  </div>
                </a>
              </li>
            </ul>
            <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
              :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
              :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
              :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false" v-if="pageAll > 1">
            </paginate>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "Search",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      SearchData: null,
      Posts: null,
      local: "zh_tw",
      pageAll: 0,
      perPage: 5,
    };
  },
  beforeRouteUpdate(to, from, next) {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/Search",
        {
          params: { Keyword: to.params.Keyword, lang: $cookies.get("is_site_lang") },
        }
      )
      .then((response) => {
        this.SearchData = response.data;
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
    next();
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/Search",
        {
          params: { Keyword: this.$route.params.Keyword, lang: $cookies.get("is_site_lang") },
        }
      )
      .then((response) => {
        this.SearchData = response.data;
        this.loadList(1);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    loadList: function (page) {
      var that = this;
      $.ajax({
        url:
          this.$store.state.src +
          this.$store.state.subDirectory +
          "/Html/SearchList",
        type: "post",
        data: {
          Keyword: this.$route.params.Keyword,
          page: page,
          perPage: that.perPage,
          lang: $cookies.get("is_site_lang"),
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.status == 1) {
            that.Posts = res.Posts;
            that.perPage = res.perPage;
            that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
            document.body.scrollTop = 0;
          }
        },
      });
    },
    gotoPost(Post) {
      if (Post.Type == 1) {
        if (Post.Blank) {
          window.open(Post.Url, "_blank");
        } else {
          location.href = Post.Url;
        }
      } else {
        this.$router.push({
          name: "Post",
          params: { PId: Post.PId },
        });
      }
    },
  },
};
</script>

