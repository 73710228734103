<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">國立臺灣藝術大學出國交換實習學生返國問卷調查</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper member form" v-if="ApplyData">
      <a href="#AC" class="accesskey accesskey_c" title="中央內容區塊" accesskey="C" name="C">:::</a>
      <div class="container">
        <h2>回國報告</h2>
        <div class="box show">
          <div class="return_box">
            <h2>問卷調查</h2>
            <div class="questionnaire">
              <p>線上填寫</p>
              <img src="img/arrow_b_2.svg" alt="">
              <a href="">「國立臺灣藝術大學出國交換實習學生返國問卷調查」</a>
            </div>
            <h2>上傳檔案</h2>

            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="R_Experience"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="R_Experience_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.R_Experience" id="R_Experience_data" />
                <div class="upload">上傳</div>
              </div>
              <label for="">
                <span>心得報告<span style="color: red;" v-if="Apply.R_Experience && Apply.R_Experience.includes('/Upload')"> (已上傳, 可更新)</span></span>
              </label>
              <p>回國交換心得報告，將公布至歷屆交換學生列表中</p>
              <p>
                心得格式下載：
                <span>
                  <button>學海惜珠學生</button>
                  <button>一般交換生、雙學位</button>
                </span>
              </p>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="R_Photo" accept=".zip,.rar,.7z"
                  @change="checkFiles">
                <input type="hidden" id="R_Photo_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.R_Photo" id="R_Photo_data" />
                <div class="upload">上傳照片(壓縮檔)</div>
              </div>
              <label for="">
                <span>照片<span style="color: red;" v-if="Apply.R_Photo && Apply.R_Photo.includes('/Upload')"> (已上傳, 可更新)</span></span>
              </label>
              <div id="preview_imgs" style=""></div>
              <p>請將交換期間照片上傳至此</p>
              <p>僅支援壓縮檔且小於10MB以下的檔案</p>
            </div>
            <div class="form-group full">
              <input type="text" class="form-control" v-model="Apply.R_Video" aria-describedby=""
                placeholder="請填寫youtube連結">
              <label for="">
                <span>影片</span>
              </label>
              <p>影片請先上傳至youtube再將連結貼入本系統</p>
            </div>
            <div class="btn">
              <button @click="send()">儲存</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

export default {
  name: "ForeignReport",
  components: {
    Head,
    Footer,
  },
  data() {
    return {
      ApplyData: null,
      Apply: null,
      local: "zh_tw",
    };
  },
  beforeCreate: function () {
    if (!$cookies.isKey("is_login_token")) {
      Swal.fire({
        title: "請先登入帳號",
        icon: "info",
        allowOutsideClick: false,
        heightAuto: false,
        willClose: () => {
          this.$router.push({
            name: "Login",
          });
        },
      });
      return;
    }
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/ContactAndReport",
        {
          params: { AId: this.$route.params.AId, token: $cookies.get("is_login_token") },
        }
      )
      .then((response) => {
        this.ApplyData = response.data;
        if (this.ApplyData.Error) {
          let that = this;
          Swal.fire({
            title: "錯誤",
            text: this.ApplyData.Error,
            icon: "error",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              that.$router.push({
                name: "Home",
              });
            },
          });
        } else {
          this.Apply = this.ApplyData.Apply;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    checkFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 10485760) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過10MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (id == "R_Experience") {
              that.Apply.R_Experience = reader.result;
            } else if (id == "R_Photo") {
              that.Apply.R_Photo = reader.result;
            }
            $("#" + id + "_data").val(reader.result);
            $("#" + id + "_name").val(file.name);
            //$("#" + id).val('');
            Swal.close();
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理檔案過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    send() {
      Swal.fire({
        title: "請稍候...",
        allowOutsideClick: false,
        showConfirmButton: false,
        heightAuto: false,
        willOpen: function () {
          Swal.showLoading();
        },
      });
      // 儲存檔案名稱
      var AttachmentNames = "";
      $("[data-namgtag='1']").each(function () {
        if (AttachmentNames.length > 0) { AttachmentNames += ", "; } else { AttachmentNames += "{"; }
        var input = $(this);
        AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
      });
      AttachmentNames += "}";
      let that = this;
      $.ajax({
        url:
          that.$store.state.src +
          that.$store.state.subDirectory +
          "/Html/ContactAndReport",
        type: "post",
        data: {
          ApplyJson: JSON.stringify(this.Apply),
          AttachmentNames: AttachmentNames,
          token: $cookies.get("is_login_token")
        },
        dataType: "json",
        error: function () { },
        success: function (res) {
          if (res.ok) {
            Swal.fire({
              title: "更新成功",
              icon: "success",
              allowOutsideClick: false,
              showConfirmButton: false,
              heightAuto: false,
              timer: 1500,
              willClose: () => {
                $('html,body').animate({ scrollTop: 0 }, 1000);
              },
            });
          } else if (res.error) {
            Swal.fire({
              title: "錯誤",
              text: res.error,
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          }
        }
      });
    },
    convertDatetimeString(time, format) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format(format);
    },
  },
};
</script>

