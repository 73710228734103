<template>
  <div>
    <Head />

    <nav class="breadcrumb_box">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
          <li class="breadcrumb-item active" aria-current="page" v-if="ApplyType == 1">外籍學位生申請</li>
          <li class="breadcrumb-item active" aria-current="page" v-if="ApplyType == 2">外籍交換生申請</li>
        </ol>
      </div>
    </nav>
    <div class="wrapper international_students_form_1 form exchange_student_form_1 out_exchange_form_1"
      v-if="ApplyInfoData">
      <a href="#AC" class="accesskey accesskey_c" title="中央內容區塊" accesskey="C" name="C">:::</a>
      <section class="container">
        <div class="">
          <h2 v-if="ApplyType == 1">
            外籍學位生申請
          </h2>
          <h2 v-if="ApplyType == 2">
            外籍交換生申請
          </h2>
          <h2 v-if="ApplyType == 3">
            出國交換學生申請
          </h2>
          <p v-if="ApplyType == 1">
            National Taiwan University of Arts Online Application for International Degree Students
          </p>
          <p v-if="ApplyType == 2">
            National Taiwan University of Arts Application Form for Inbound Exchange and Visiting Students
          </p>
          <p v-if="ApplyType == 3">
            National Taiwan University of Arts
          </p>
          <div v-show="Step == 0" v-html="ApplyInfoData.ApplyTypeInfo">
          </div>
          <div class="btn_box" v-show="Step == 0">
            <button @click="gotoStep(1)">開始申請</button>
          </div>
          <div class="box review" v-if="Apply && (ApplyType == 1 || ApplyType == 2)" v-show="Step == 1 || Step == 3">
            <div class="form-group full">
              <Select2 class="form-control" v-model="Apply.Program" :options="ApplyData.Departments"
                :settings="{ placeholder: '請選擇申請系所' }" />
              <label class="Program" for=""><span><span class="required">*</span>選擇申請系所(只限申請一系)</span>Program (Please
                choose one program)</label>
            </div>
          </div>
          <div class="box review" v-if="Apply && (ApplyType == 1 || ApplyType == 2)" v-show="Step == 1 || Step == 3">
            <h2>申請人資料<span>Personal Information of Applicant</span></h2>
            <div class="personal_box">
              <div class="form-group">
                <input type="text" class="form-control" v-model="Apply.Name" data-required="1" data-required-msg="請填寫中文姓名"
                  aria-describedby="" placeholder="請填寫中文姓名">
                <label for=""><span>中文姓名</span>Chinese Name</label>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" v-model="Apply.Name_EN" data-required="1"
                  data-required-msg="請填寫英文姓名" aria-describedby="" placeholder="請填寫英文姓名">
                <label for=""><span><span class="required">*</span>英文姓名</span>Full Name</label>
              </div>
              <div class="form-group">
                <input type="date" class="form-control" v-model="Apply.Birthday" data-required="1"
                  data-required-msg="請填寫英文姓名" aria-describedby="" placeholder="">
                <label for=""><span><span class="required">*</span>生日(西元年月日)</span> Date of Birth</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.Gender" data-required="1" data-required-msg="請選擇性別">
                  <option value="-1" disabled selected>請選擇性別</option>
                  <option value="1">男</option>
                  <option value="2">女</option>
                  <option value="3">非二元</option>
                  <option value="4">其他</option>
                </select>
                <label for=""><span><span class="required">*</span>性別</span> Gender</label>
              </div>
            </div>
            <div class="file_box">
              <div class="custom_file">
                <input ref="upload" type="file" class="" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg" id="Photo"
                  @change="previewFiles">
                <img id="Photo_preview" :src="Apply.Photo ? Apply.Photo : '#'"
                  :style="Apply.Photo ? 'opacity: 1;' : ''" />
                <div class="upload">選擇檔案</div>
                <p>
                  <span><span class="required">*</span>上傳證件照 Headshot Photo</span>
                  <br>
                  僅支援圖片且小於10MB以下的檔案
                </p>
                <input type="hidden" v-model="Apply.Photo" id="photo_data" data-required="1" data-required-msg="請上傳證件照" />
              </div>
            </div>
            <div class="form-group">
              <!-- <select class="form-control" v-model="Apply.Nationality" data-required="1" data-required-msg="請選擇國籍">
                <option value="" disabled selected>請選擇國籍</option>
                <option v-for="(Country, index) in ApplyData.Countrys" :key="index" :value="Country.Value">{{ Country.Text }}</option>
              </select> -->
              <Select2 class="form-control" v-model="Apply.Nationality" :options="ApplyData.Countrys"
                :settings="{ placeholder: '請選擇國籍' }" />
              <label for=""><span><span class="required">*</span>國籍</span>Nationality</label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.PassportNumber" data-required="1"
                data-required-msg="請填寫護照號碼" aria-describedby="" placeholder="請填寫護照號碼">
              <label for=""><span><span class="required">*</span>護照號碼</span>Passport Number</label>
            </div>
            <div class="form-group">
              <vue-tel-input ref="Phone" class="form-control" v-model="Apply.Phone" :mode="'international'"
                :defaultCountry="886" :dropdownOptions="{ showFlags: true, showDialCodeInSelection: true }"
                :inputOptions="{ placeholder: '請填寫電話' }" autocomplete="new-phone"></vue-tel-input>
              <!-- <input type="text" class="form-control" v-model="Apply.Phone" data-required="1" data-required-msg="請填寫電話"
                aria-describedby="" placeholder="請填寫電話"> -->
              <label for=""><span><span class="required">*</span>電話(加國碼)</span>Phone Number</label>
            </div>
            <div class="form-group">
              <input type="email" class="form-control" v-model="Apply.Email" data-required="1" data-required-msg="請填寫電子郵件"
                aria-describedby="" placeholder="請填寫電子郵件">
              <label for=""><span><span class="required">*</span>電子郵件</span>Email</label>
            </div>
            <div class="form-group full">
              <input type="text" class="form-control" v-model="Apply.Address" data-required="1"
                data-required-msg="請填寫居住地址" aria-describedby="" placeholder="請填寫居住地址">
              <label for=""><span><span class="required">*</span>居住地址</span>Address</label>
            </div>
          </div>
          <div class="box review" v-if="Apply && (ApplyType == 1 || ApplyType == 2)" v-show="Step == 1 || Step == 3">
            <h2>緊急聯絡人<span>Emergency Contact Person</span></h2>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.EmergencyName" data-required="1"
                data-required-msg="請填寫姓名" aria-describedby="" placeholder="請填寫姓名">
              <label for=""><span><span class="required">*</span>姓名</span>Relation</label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.EmergencyRelation" data-required="1"
                data-required-msg="請填寫關係" aria-describedby="" placeholder="請填寫關係">
              <label for=""><span><span class="required">*</span>關係</span>Full Name</label>
            </div>
            <div class="form-group">
              <vue-tel-input ref="EmergencyPhone" class="form-control" v-model="Apply.EmergencyPhone"
                :mode="'international'" :defaultCountry="886"
                :dropdownOptions="{ showFlags: true, showDialCodeInSelection: true }"
                :inputOptions="{ placeholder: '請填寫電話' }" autocomplete="new-phone"></vue-tel-input>
              <label for=""><span><span class="required">*</span>電話(加國碼)</span>Phone Number</label>
            </div>
            <div class="form-group">
              <input type="email" class="form-control" v-model="Apply.EmergencyEmail" data-required="1"
                data-required-msg="請填寫電子郵件" aria-describedby="" placeholder="請填寫電子郵件">
              <label for=""><span><span class="required">*</span>電子郵件</span>Email</label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 1" v-show="Step == 1 || Step == 3">
            <h2>家庭資料<span>Parents Information</span></h2>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T1_ParentsFatherName"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫父親姓名" aria-describedby=""
                placeholder="請填寫父親姓名">
              <label for=""><span><span class="required">*</span>父親姓名</span>Full Name of Applicant's Father</label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T1_ParentsFatherNationality"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫父親國籍" aria-describedby=""
                placeholder="請填寫父親國籍">
              <label for=""><span><span class="required">*</span>父親國籍</span>Nationality of Applicant's Father</label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T1_ParentsMotherName"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫母親姓名" aria-describedby=""
                placeholder="請填寫母親姓名">
              <label for=""><span><span class="required">*</span>母親姓名</span>Full Name of Applicant's Mother</label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T1_ParentsMotherNationality"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫母親國籍" aria-describedby=""
                placeholder="請填寫母親國籍">
              <label for=""><span><span class="required">*</span>母親國籍</span>Nationality of Applicant's Mother</label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 1" v-show="Step == 1 || Step == 3">
            <h2>最高學歷<span>The Highest-Level Education</span></h2>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T1_EducationLeve" :data-required="ApplyType == 1 ? '1' : '0'"
                data-required-msg="請選擇學歷">
                <option value="" disabled selected>請選擇學歷</option>
                <option value="中學">中學</option>
                <option value="學士">學士</option>
                <option value="碩士">碩士</option>
                <option value="博士">博士</option>
              </select>
              <label for=""><span><span class="required">*</span>學歷</span>Education Leve</label>
            </div>
            <div class="form-group">
              <Select2 class="form-control" v-model="Apply.T1_CountryOfSchool" :options="ApplyData.Countrys"
                :settings="{ placeholder: '請選擇學校國家' }" />
              <!-- <select class="form-control" v-model="Apply.T1_CountryOfSchool" :data-required="ApplyType == 1 ? '1' : '0'"
                data-required-msg="請選擇學校國家">
                <option value="" disabled selected>請選擇學校國家</option>
                <option v-for="(Country, index) in ApplyData.Countrys" :key="index" :value="Country.Value">{{ Country.Text
                }}</option>
              </select> -->
              <label for=""><span><span class="required">*</span>學校國家</span>Country of School/Institute</label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T1_SchoolName"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫學校名稱(中文)" aria-describedby=""
                placeholder="請填寫學校名稱(中文)">
              <label for=""><span><span class="required">*</span>學校名稱(中文)</span>School/Institute Name in Chinese</label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T1_SchoolName_EN"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫學校名稱(英文)" aria-describedby=""
                placeholder="請填寫學校名稱(英文)">
              <label for=""><span><span class="required">*</span>學校名稱(英文) </span>School/Institute Name in English</label>
            </div>
            <div class="form-group month_box">
              <!-- <div class=""> -->
              <input type="month" class="form-control" v-model="Apply.T1_StudyDurationStart"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫就學期間(起)" aria-describedby=""
                placeholder="">
              <input type="month" class="form-control" v-model="Apply.T1_StudyDurationEnd"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫學就學期間(迄)" aria-describedby=""
                placeholder="">
              <!-- </div> -->
              <label for=""><span><span class="required">*</span>就學期間</span>Study Duration</label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T1_Department"
                :data-required="ApplyType == 1 ? '1' : '0'" data-required-msg="請填寫系所/主修" aria-describedby=""
                placeholder="請填寫系所/主修">
              <label for=""><span><span class="required">*</span>系所/主修</span>Department/ Major</label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 2" v-show="Step == 1 || Step == 3">
            <h2>在學狀況<span>Education Information</span></h2>
            <div class="form-group full">
              <input type="" class="form-control" v-model="Apply.T2_SchoolName"
                :data-required="ApplyType == 2 ? '1' : '0'" data-required-msg="請填寫目前就讀學校" aria-describedby=""
                placeholder="請填寫目前就讀學校">
              <label for=""><span><span class="required">*</span>目前就讀學校</span>Current School Name</label>
            </div>
            <div class="form-group">
              <input type="" class="form-control" v-model="Apply.T2_Department"
                :data-required="ApplyType == 2 ? '1' : '0'" data-required-msg="請填寫系所" aria-describedby=""
                placeholder="請填寫系所">
              <label for=""><span><span class="required">*</span>系所</span>Department</label>
            </div>
            <div class="form-group">
              <input type="" class="form-control" v-model="Apply.T2_YearInSchool"
                :data-required="ApplyType == 2 ? '1' : '0'" data-required-msg="請填寫年級" aria-describedby=""
                placeholder="請填寫年級">
              <label for=""><span><span class="required">*</span>年級</span>Year in School</label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 2" v-show="Step == 1 || Step == 3">
            <h2>交換計畫<span>Exchange Program</span></h2>
            <div class="form-group full">
              <input type="" class="form-control" v-model="Apply.T2_ExchangeDepartment"
                :data-required="ApplyType == 2 ? '1' : '0'" data-required-msg="請填寫系所" aria-describedby=""
                placeholder="請填寫系所">
              <label for=""><span><span class="required">*</span>系所</span>Department or Graduate Institute in which you
                intend to exchange in</label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T2_ExchangeProgram" :data-required="ApplyType == 2 ? '1' : '0'"
                data-required-msg="請選擇學制">
                <option value="" disabled selected>請選擇學制</option>
                <option value="學士">學士 Bachelor</option>
                <option value="碩士">碩士 Master</option>
                <option value="博士">博士 Ph.D</option>
              </select>
              <label for=""><span><span class="required">*</span>學制</span>The program in this department in which you
                intend to exchange in</label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T2_ExchangePeriod" :data-required="ApplyType == 2 ? '1' : '0'"
                data-required-msg="請選擇交換時間">
                <option value="" disabled selected>請選擇交換時間</option>
                <option value="一學期">一學期 One Semester</option>
                <option value="一學年">一學年 One (Academic) Year</option>
              </select>
              <label for=""><span><span class="required">*</span>交換時間</span>Exchange Period</label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 2" v-show="Step == 1 || Step == 3">
            <h2>語言能力<span>Language Proficiency</span></h2>
            <div class="language">
              <div class="form-group">
                <span class="required">*</span><input type="" class="form-control" id="" aria-describedby=""
                  placeholder="中文 Chinese" disabled>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_CN_R" :data-required="ApplyType == 2 ? '1' : '0'"
                  data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>讀</span>Reading</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_CN_W" :data-required="ApplyType == 2 ? '1' : '0'"
                  data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>寫</span>Writing</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_CN_L" :data-required="ApplyType == 2 ? '1' : '0'"
                  data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>聽</span>Listening</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_CN_S" :data-required="ApplyType == 2 ? '1' : '0'"
                  data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>說</span>Speaking</label>
              </div>
            </div>
            <div class="language">
              <div class="form-group">
                <span class="required">*</span><input type="" class="form-control" id="" aria-describedby=""
                  placeholder="英文 English" disabled>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_EN_R" :data-required="ApplyType == 2 ? '1' : '0'"
                  data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>讀</span>Reading</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_EN_W" :data-required="ApplyType == 2 ? '1' : '0'"
                  data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>寫</span>Writing</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_EN_L" :data-required="ApplyType == 2 ? '1' : '0'"
                  data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>聽</span>Listening</label>
              </div>

              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_EN_S" :data-required="ApplyType == 2 ? '1' : '0'"
                  data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>說</span>Speaking</label>
              </div>
            </div>
            <div class="language">
              <div class="form-group">
                <input type="" class="form-control" v-model="Apply.T2_LangSkill_O1_Name" placeholder="請填寫語言">
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_O1_R"
                  :data-required="Apply.T2_LangSkill_O1_Name && ApplyType == 2 ? '1' : '0'" data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>讀</span>Reading</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_O1_W"
                  :data-required="Apply.T2_LangSkill_O1_Name && ApplyType == 2 ? '1' : '0'" data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>寫</span>Writing</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_O1_L"
                  :data-required="Apply.T2_LangSkill_O1_Name && ApplyType == 2 ? '1' : '0'" data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>聽</span>Listening</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_O1_S"
                  :data-required="Apply.T2_LangSkill_O1_Name && ApplyType == 2 ? '1' : '0'" data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>說</span>Speaking</label>
              </div>
            </div>
            <div class="language">
              <div class="form-group">
                <input type="" class="form-control" v-model="Apply.T2_LangSkill_O2_Name" aria-describedby=""
                  placeholder="請填寫語言">
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_O2_R"
                  :data-required="Apply.T2_LangSkill_O2_Name && ApplyType == 2 ? '1' : '0'" data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>讀</span>Reading</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_O2_W"
                  :data-required="Apply.T2_LangSkill_O2_Name && ApplyType == 2 ? '1' : '0'" data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>寫</span>Writing</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_O2_L"
                  :data-required="Apply.T2_LangSkill_O2_Name && ApplyType == 2 ? '1' : '0'" data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>聽</span>Listening</label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T2_LangSkill_O2_S"
                  :data-required="Apply.T2_LangSkill_O2_Name && ApplyType == 2 ? '1' : '0'" data-required-msg="請選擇語言能力">
                  <option value="-1" disabled selected>請選擇</option>
                  <option value="4">優 Excellent</option>
                  <option value="3">良 Good</option>
                  <option value="2">可 Fair</option>
                  <option value="1">差 Poor</option>
                </select>
                <label for=""><span>說</span>Speaking</label>
              </div>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 3" v-show="Step == 1 || Step == 3">
            <h2>個人基本資料</h2>
            <div class="personal_box">
              <div class="form-group">
                <input type="text" class="form-control" v-model="Apply.T3_Name" data-required="1"
                  data-required-msg="請填寫中文姓名" aria-describedby="" placeholder="請填寫中文姓名">
                <label for=""><span>中文姓名</span>Chinese Name</label>
              </div>
              <div class="form-group two">
                <input type="text" class="form-control" v-model="Apply.T3_Name_EN_First" data-required="1"
                  data-required-msg="請填寫英文姓名" aria-describedby="" placeholder="first name">
                <input type="text" class="form-control" v-model="Apply.T3_Name_EN_Last" data-required="1"
                  data-required-msg="請填寫英文姓名" aria-describedby="" placeholder="last name">
                <label for=""><span><span class="required">*</span>英文姓名</span></label>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" v-model="Apply.T3_IdentityNumber" data-required="1"
                  data-required-msg="請填寫身分證號" aria-describedby="" placeholder="請填寫身分證號">
                <label for=""><span><span class="required">*</span>身分證號</span></label>
              </div>
              <div class="form-group">
                <select class="form-control" v-model="Apply.T3_Gender" data-required="1" data-required-msg="請選擇性別">
                  <option value="-1" disabled selected>請選擇性別</option>
                  <option value="1">男</option>
                  <option value="2">女</option>
                  <option value="3">非二元</option>
                  <option value="4">其他</option>
                </select>
                <label for=""><span><span class="required">*</span>性別</span> Gender</label>
              </div>
              <div class="form-group">
                <input type="date" class="form-control" v-model="Apply.T3_Birthday" data-required="1"
                  data-required-msg="請填寫英文姓名" aria-describedby="" placeholder="">
                <label for=""><span><span class="required">*</span>生日(西元年月日)</span> Date of Birth</label>
              </div>
            </div>
            <div class="file_box">
              <div class="custom_file">
                <input ref="upload" type="file" class="" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg" id="Photo"
                  @change="previewFiles">
                <img id="Photo_preview" :src="Apply.Photo ? Apply.Photo : '#'"
                  :style="Apply.Photo ? 'opacity: 1;' : ''" />
                <div class="upload">選擇檔案</div>
                <p>
                  <span><span class="required">*</span>上傳證件照 Headshot Photo</span>
                  <br>
                  僅支援圖片且小於10MB以下的檔案
                </p>
                <input type="hidden" v-model="Apply.Photo" id="photo_data" data-required="1" data-required-msg="請上傳證件照" />
              </div>
            </div>
            <div class="form-group">
              <vue-tel-input ref="T3_Phone" class="form-control" v-model="Apply.T3_Phone" :mode="'international'"
                :defaultCountry="886" :dropdownOptions="{ showFlags: true, showDialCodeInSelection: true }"
                :inputOptions="{ placeholder: '請填寫手機' }" autocomplete="new-phone"></vue-tel-input>
              <label for=""><span><span class="required">*</span>電話(加國碼)</span>Phone Number</label>
            </div>
            <div class="form-group">
              <input type="email" class="form-control" v-model="Apply.T3_Email" data-required="1"
                data-required-msg="請填寫電子郵件" aria-describedby="" placeholder="請填寫電子郵件">
              <label for=""><span><span class="required">*</span>電子郵件</span></label>
            </div>
            <div class="form-group two">
              <select class="form-control" v-model="Apply.T3_College" data-required="1" data-required-msg="請選擇學院"
                @change="Apply.T3_Department = ''">
                <option value="" disabled selected>請選擇學院</option>
                <option value="美術學院">美術學院</option>
                <option value="設計學院">設計學院</option>
                <option value="傳播學院">傳播學院</option>
                <option value="表演藝術學院">表演藝術學院</option>
                <option value="人文學院">人文學院</option>
              </select>
              <select class="form-control" v-model="Apply.T3_Department" data-required="1" data-required-msg="請選擇系所">
                <option value="" disabled selected>請選擇系所</option>
                <option v-if="Apply.T3_College == '美術學院'" value="美術學系">美術學系</option>
                <option v-if="Apply.T3_College == '美術學院'" value="書畫藝術學系">書畫藝術學系</option>
                <option v-if="Apply.T3_College == '美術學院'" value="雕塑學系">雕塑學系</option>
                <option v-if="Apply.T3_College == '美術學院'" value="古蹟藝術修護學系">古蹟藝術修護學系</option>
                <option v-if="Apply.T3_College == '美術學院'" value="版畫藝術碩士班">版畫藝術碩士班</option>
                <option v-if="Apply.T3_College == '美術學院'" value="當代視覺文化博士班">當代視覺文化博士班</option>
                <option v-if="Apply.T3_College == '設計學院'" value="美術學系">美術學系</option>
                <option v-if="Apply.T3_College == '設計學院'" value="視覺傳達設計學系">視覺傳達設計學系</option>
                <option v-if="Apply.T3_College == '設計學院'" value="工藝設計學系">工藝設計學系</option>
                <option v-if="Apply.T3_College == '設計學院'" value="多媒體動畫藝術學系">多媒體動畫藝術學系</option>
                <option v-if="Apply.T3_College == '設計學院'" value="創意產業研究所">創意產業研究所</option>
                <option v-if="Apply.T3_College == '傳播學院'" value="美術學系">美術學系</option>
                <option v-if="Apply.T3_College == '傳播學院'" value="圖文傳播藝術學系">圖文傳播藝術學系</option>
                <option v-if="Apply.T3_College == '傳播學院'" value="廣播電視學系">廣播電視學系</option>
                <option v-if="Apply.T3_College == '傳播學院'" value="電影學系">電影學系</option>
                <option v-if="Apply.T3_College == '傳播學院'" value="影音創作與數位媒體產業研究所">影音創作與數位媒體產業研究所</option>
                <option v-if="Apply.T3_College == '表演藝術學院'" value="美術學系">美術學系</option>
                <option v-if="Apply.T3_College == '表演藝術學院'" value="戲劇學系">戲劇學系</option>
                <option v-if="Apply.T3_College == '表演藝術學院'" value="表演藝術跨領域博士班">表演藝術跨領域博士班</option>
                <option v-if="Apply.T3_College == '表演藝術學院'" value="中國音樂學系">中國音樂學系</option>
                <option v-if="Apply.T3_College == '表演藝術學院'" value="音樂學系">音樂學系</option>
                <option v-if="Apply.T3_College == '表演藝術學院'" value="舞蹈學系">舞蹈學系</option>
                <option v-if="Apply.T3_College == '表演藝術學院'" value="跨域表演藝術研究所">跨域表演藝術研究所</option>
                <option v-if="Apply.T3_College == '人文學院'" value="美術學系">美術學系</option>
                <option v-if="Apply.T3_College == '人文學院'" value="藝術管理與文化政策研究所">藝術管理與文化政策研究所</option>
                <option v-if="Apply.T3_College == '人文學院'" value="藝術與人文教學研究所">藝術與人文教學研究所</option>
              </select>
              <label for=""><span><span class="required">*</span>就讀系所</span></label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T3_EduLevel" data-required="1" data-required-msg="請選擇學位別">
                <option value="" disabled selected>請選擇學位別</option>
                <option value="學士班">學士班</option>
                <option value="碩士班">碩士班</option>
                <option value="博士班">博士班</option>
                <option value="碩士在職專班">碩士在職專班</option>
              </select>
              <label for=""><span><span class="required">*</span>學位別</span></label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T3_Grade" data-required="1" data-required-msg="請選擇學位別">
                <option value="" disabled selected>請選擇年級</option>
                <option value="大一">大一</option>
                <option value="大二">大二</option>
                <option value="大三">大三</option>
                <option value="大四">大四</option>
                <option value="大五">大五</option>
                <option value="大六">大六</option>
                <option value="大七">大七</option>
                <option value="碩一">碩一</option>
                <option value="碩二">碩二</option>
                <option value="碩三">碩三</option>
                <option value="博一">博一</option>
                <option value="博二">博二</option>
                <option value="博三">博三</option>
                <option value="博四">博四</option>
                <option value="博五">博五</option>
                <option value="博六">博六</option>
              </select>
              <label for=""><span><span class="required">*</span>年級</span></label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T3_StudentNumber" data-required="1"
                data-required-msg="請填寫學號" aria-describedby="" placeholder="請填寫學號">
              <label for=""><span><span class="required">*</span>學號</span></label>
            </div>
            <div class="form-group full">
              <input type="text" class="form-control" v-model="Apply.T3_MainDepartment" data-required="1"
                data-required-msg="請填寫主修/專攻" aria-describedby="" placeholder="請填寫主修/專攻">
              <label for=""><span><span class="required">*</span>主修/專攻(中文)</span></label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 3" v-show="Step == 1 || Step == 3">
            <h2>緊急聯絡人</h2>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T3_EmergencyName" data-required="1"
                data-required-msg="請填寫姓名" aria-describedby="" placeholder="請填寫姓名">
              <label for=""><span><span class="required">*</span>姓名</span></label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T3_EmergencyName_EN" data-required="1"
                data-required-msg="請填寫英文姓名" aria-describedby="" placeholder="請填寫英文姓名">
              <label for=""><span><span class="required">*</span>英文姓名</span></label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T3_EmergencyRelation" data-required="1"
                data-required-msg="請填寫關係" aria-describedby="" placeholder="請填寫關係">
              <label for=""><span><span class="required">*</span>關係</span></label>
            </div>
            <div class="form-group">
              <input type="email" class="form-control" v-model="Apply.T3_EmergencyEmail" data-required="1"
                data-required-msg="請填寫電子郵件" aria-describedby="" placeholder="請填寫電子郵件">
              <label for=""><span>電子郵件</span></label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T3_EmergencyHomePhone" data-required="1"
                data-required-msg="請填寫住家電話" aria-describedby="" placeholder="請填寫住家電話">
              <label for=""><span><span class="required">*</span>住家電話</span></label>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" v-model="Apply.T3_EmergencyPhone" data-required="1"
                data-required-msg="請填寫手機" aria-describedby="" placeholder="請填寫手機">
              <label for=""><span><span class="required">*</span>手機</span></label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 3" v-show="Step == 1 || Step == 3">
            <h2>申請資料</h2>
            <div class="form-group full">
              <!-- <input type="text" class="form-control" v-model="Apply.T3_ApplySchool" data-required="1"
                data-required-msg="請填寫申請學校(志願1)" aria-describedby="" placeholder="請填寫申請學校"> -->
                <Select2 class="form-control" v-model="Apply.T3_ApplySchool" :options="ApplyData.ContractSchools"
                :settings="{ placeholder: '請選擇申請學校' }" />
              <label for=""><span><span class="required">*</span>申請學校(志願1)</span></label>
            </div>
            <div class="form-group">
              <input type="" class="form-control" v-model="Apply.T3_ApplyDepartment" data-required="1"
                data-required-msg="請填寫申請系所" aria-describedby="" placeholder="請填寫申請系所">
              <label for=""><span><span class="required">*</span>申請系所_中文(志願1)</span></label>
            </div>
            <div class="form-group">
              <input type="" class="form-control" v-model="Apply.T3_ApplyDepartment_EN" data-required="1"
                data-required-msg="請填寫申請系所" aria-describedby="" placeholder="請填寫申請系所">
              <label for=""><span><span class="required">*</span>申請系所_英文(志願1)</span></label>
            </div>
            <div class="form-group month_box">
              <input type="month" class="form-control" v-model="Apply.T3_StudyDurationStart" data-required="1"
                data-required-msg="請填寫修讀期間(起)" aria-describedby="" placeholder="">
              <input type="month" class="form-control" v-model="Apply.T3_StudyDurationEnd" data-required="1"
                data-required-msg="請填寫修讀期間(迄)" aria-describedby="" placeholder="">
              <label for=""><span><span class="required">*</span>就學期間</span>Study Duration</label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T3_ApplyProgram" data-required="1" data-required-msg="請選擇學制">
                <option value="" disabled selected>請選擇學制</option>
                <option value="學士班">學士班</option>
                <option value="碩士班">碩士班</option>
                <option value="博士班">博士班</option>
                <option value="碩士在職專班">碩士在職專班</option>
              </select>
              <label for=""><span><span class="required">*</span>申請學制</span></label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T3_ApplyGrade" data-required="1" data-required-msg="請選擇申請年級">
                <option value="" disabled selected>請選擇申請年級</option>
                <option value="大一">大一</option>
                <option value="大二">大二</option>
                <option value="大三">大三</option>
                <option value="大四">大四</option>
                <option value="大五">大五</option>
                <option value="大六">大六</option>
                <option value="大七">大七</option>
                <option value="碩一">碩一</option>
                <option value="碩二">碩二</option>
                <option value="碩三">碩三</option>
                <option value="博一">博一</option>
                <option value="博二">博二</option>
                <option value="博三">博三</option>
                <option value="博四">博四</option>
                <option value="博五">博五</option>
                <option value="博六">博六</option>
              </select>
              <label for=""><span><span class="required">*</span>申請年級</span></label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T3_BeSoldier" data-required="1" data-required-msg="請選擇是否需服兵役">
                <option value="" disabled selected>請選擇</option>
                <option value="是">是</option>
                <option value="否">否</option>
              </select>
              <label for=""><span><span class="required">*</span>是否需服兵役</span></label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T3_Award" data-required="1" data-required-msg="請選擇競賽得獎證明">
                <option value="" disabled selected>請選擇</option>
                <option value="是">是</option>
                <option value="否">否</option>
              </select>
              <label for=""><span><span class="required">*</span>競賽得獎證明</span></label>
            </div>
            <div class="form-group">
              <select class="form-control" v-model="Apply.T3_Identity">
                <option value="" selected>無</option>
                <option value="港生">港生</option>
                <option value="澳生">澳生</option>
                <option value="僑生">僑生</option>
                <option value="外籍生">外籍生</option>
              </select>
              <label for=""><span><span class="required">*</span>具有以下身份</span></label>
            </div>
            <div class="score_box">
              <div class="title">
                <h3 class="">語檢成績(有效期限內)</h3>
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" id="cbox_T3_TestScore_NoNeed"
                    v-model="Apply.T3_TestScore_NoNeed">
                  <label class="form-check-label" for="cbox_T3_TestScore_NoNeed">不需檢附語檢成績</label>
                </div>
              </div>
              <hr>
              <div class="form-group" v-show="!Apply.T3_TestScore_NoNeed">
                <input type="" class="form-control" v-model="Apply.T3_TestName"
                  :data-required="!Apply.T3_TestScore_NoNeed ? '1' : '0'" data-required-msg="請填寫測驗名稱" aria-describedby=""
                  placeholder="請填寫測驗名稱">
                <label for=""><span><span class="required">*</span>測驗名稱</span></label>
              </div>
              <div class="form-group" v-show="!Apply.T3_TestScore_NoNeed">
                <input type="" class="form-control" v-model="Apply.T3_TestScore"
                  :data-required="!Apply.T3_TestScore_NoNeed ? '1' : '0'" data-required-msg="請填寫分數" aria-describedby=""
                  placeholder="請填寫分數">
                <label for=""><span><span class="required">*</span>分數</span></label>
              </div>
              <div class="form-group" v-show="!Apply.T3_TestScore_NoNeed">
                <input type="" class="form-control" v-model="Apply.T3_TestLevel"
                  :data-required="!Apply.T3_TestScore_NoNeed ? '1' : '0'" data-required-msg="請填寫等級" aria-describedby=""
                  placeholder="請填寫等級">
                <label for=""><span><span class="required">*</span>等級</span></label>
              </div>
              <div class="form-group" v-show="!Apply.T3_TestScore_NoNeed">
                <input type="date" class="form-control" v-model="Apply.T3_TestDay"
                  :data-required="!Apply.T3_TestScore_NoNeed ? '1' : '0'" data-required-msg="請填寫考試日期" aria-describedby=""
                  placeholder="">
                <label for=""><span><span class="required">*</span>考試日期</span></label>
              </div>
            </div>
            <div class="score_box">
              <div class="title">
                <h3 class="">學業成績</h3>
              </div>
              <hr>
              <div class="form-group">
                <input type="" class="form-control" v-model="Apply.T3_AverageScore" data-required="1"
                  data-required-msg="請填寫歷年學業平均成績（各學期加總平均）" aria-describedby="" placeholder="請填寫歷年學業平均成績（各學期加總平均）">
                <label for=""><span><span class="required">*</span>歷年學業平均成績（各學期加總平均）</span></label>
              </div>
              <div class="form-group">
                <input type="" class="form-control checkbox_1" v-show="!Apply.T3_Ranking_NoNeed"
                  v-model="Apply.T3_Ranking" :data-required="!Apply.T3_Ranking_NoNeed ? '1' : '0'"
                  data-required-msg="請填寫歷年班排名/人數" aria-describedby="" placeholder="請填寫歷年班排名/人數">
                <label for=""><span><span class="required">*</span>歷年班排名/人數</span></label>
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" id="cbox_T3_Ranking_NoNeed"
                    v-model="Apply.T3_Ranking_NoNeed">
                  <label class="form-check-label" for="cbox_T3_Ranking_NoNeed">我是研究生免填</label>
                </div>
              </div>
              <div class="form-group">
                <input type="" class="form-control checkbox_2" v-show="!Apply.T3_GPA_NoNeed" v-model="Apply.T3_GPA"
                  :data-required="!Apply.T3_GPA_NoNeed ? '1' : '0'" data-required-msg="請填寫歷年學業GPA成績" aria-describedby=""
                  placeholder="請填寫歷年學業GPA成績">
                <label for=""><span><span class="required">*</span>歷年學業GPA成績</span></label>
                <div class="form-group form-check">
                  <input type="checkbox" class="form-check-input" id="cbox_T3_GPA_NoNeed" v-model="Apply.T3_GPA_NoNeed">
                  <label class="form-check-label" for="cbox_T3_GPA_NoNeed">我不用提供歷年英文成績單</label>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_box" v-if="Apply" v-show="Step == 1">
            <button @click="gotoStep(2)">下一步</button>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 1" v-show="Step == 2 || Step == 3">
            <h2>上傳申請文件<span></span></h2>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_Passport" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T1_Passport_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_Passport" id="T1_Passport_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳護照" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>護照</span>Copy of Passport<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T1_Passport"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_HighCert" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T1_HighCert_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_HighCert" id="T1_HighCert_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'"
                  data-required-msg="請上傳最高學歷或同等學力證明文件、學歷認證相關證明文件(需經外館驗證)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>最高學歷或同等學力證明文件、學歷認證相關證明文件(需經外館驗證)</span>The highest-level
                degree certification(Verified by Taiwan Office)<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T1_HighCert"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_Transcript"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T1_Transcript_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_Transcript" id="T1_Transcript_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳歷年成績單(需經外館驗證)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>歷年成績單(需經外館驗證)</span>Full list of transcript (Verified by
                Taiwan Office)<span style="color: red;" v-if="Apply.AId != -1 && Apply.T1_Transcript"> (已上傳,
                  可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_BirthCertificate"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T1_BirthCertificate_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_BirthCertificate" id="T1_BirthCertificate_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳出生證明(無法繳交者請使用範本)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>出生證明(無法繳交者請使用範本)</span>Birth Certificate (Use attachment
                if not available)<span style="color: red;" v-if="Apply.AId != -1 && Apply.T1_BirthCertificate"> (已上傳,
                  可更新)</span></label>
              <div class="download_box">
                <a class="download" href="files/no_birth_cert.pdf">範本下載</a>
              </div>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_ProofOfFinancial"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T1_ProofOfFinancial_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_ProofOfFinancial" id="T1_ProofOfFinancial_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳財力證明" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>財力證明</span>Proof of financial statement (Bank account
                savings)<span style="color: red;" v-if="Apply.AId != -1 && Apply.T1_ProofOfFinancial"> (已上傳,
                  可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_SelfIntroduction"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T1_SelfIntroduction_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_SelfIntroduction" id="T1_SelfIntroduction_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳自傳" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>自傳</span>Self-Introduction<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T1_SelfIntroduction"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_StudyPlan"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T1_StudyPlan_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_StudyPlan" id="T1_StudyPlan_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳學習計畫書" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>學習計畫書</span>Study Plan<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T1_StudyPlan"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_TOCFL" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T1_TOCFL_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_TOCFL" id="T1_TOCFL_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳中文能力證明(TOCFL)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>中文能力證明(TOCFL)</span>Chinese Proficiency Certificate
                (TOCFL)<span style="color: red;" v-if="Apply.AId != -1 && Apply.T1_TOCFL"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_Declaration"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T1_Declaration_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_Declaration" id="T1_Declaration_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳申請具結書(請見說明)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>申請具結書(請見說明)</span>Declaration (Please see the
                instruction)<span style="color: red;" v-if="Apply.AId != -1 && Apply.T1_Declaration"> (已上傳,
                  可更新)</span></label>
              <div class="download_box">
                <a class="download" href="files/declaration.pdf">範本下載</a>
              </div>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_PersonalDataAgree"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T1_PersonalDataAgree_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_PersonalDataAgree" id="T1_PersonalDataAgree_data"
                  :data-required="Step == 2 && ApplyType == 1 ? '1' : '0'" data-required-msg="請上傳個人資料提供同意書(請見說明)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>個人資料提供同意書(請見說明)</span>Letter of agreement of providing
                personal information.(Please see the instruction)<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T1_PersonalDataAgree"> (已上傳, 可更新)</span></label>
              <div class="download_box">
                <a class="download" href="files/letter_of_agreement_of_providing.pdf">範本下載</a>
              </div>
            </div>
            <div class="form-group full other_box">
              <div class="custom_file">
                <input type="file" id="T1_Other" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T1_Other_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_Other" id="T1_Other_data" />
                <div class="upload">上傳</div>
              </div>
              <input type="text" class="form-control" v-model="Apply.T1_OtherURL" aria-describedby="" placeholder="請填寫網址">
              <label for=""><span>其它補充文件(如作品集)</span>Other requirement documents (Ex. Portfolio)<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T1_Other"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T1_Scholarship"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T1_Scholarship_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T1_Scholarship" id="T1_Scholarship_data" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span>教育部或外交部臺灣獎學金證明請提供(如有)</span>Certificate of Taiwan Scholarship from MOE/ MOFA (if
                available)<span style="color: red;" v-if="Apply.AId != -1 && Apply.T1_Scholarship"> (已上傳,
                  可更新)</span></label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 2 && Apply.Nationality != 'A00'"
            v-show="Step == 2 || Step == 3">
            <h2>上傳申請文件<span></span></h2>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_Recommend"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_Recommend_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_Recommend" id="T2_Recommend_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality != 'A00' ? '1' : '0'"
                  data-required-msg="請上傳臺灣藝術大學姊妹校交換生推薦表(.pdf)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>臺灣藝術大學姊妹校交換生推薦表(.pdf)</span>Nomination Form for Exchange
                Study (.pdf)<span style="color: red;" v-if="Apply.AId != -1 && Apply.T2_Recommend"> (已上傳,
                  可更新)</span></label>
              <div class="download_box">
                <a class="download" href="files/nomination_form_for_exchange.xlsx">範本下載</a>
                <a class="download" href="files/nomination_form_for_exchange_en.xlsx" style="margin-left: 5px;">Template
                  Download</a>
              </div>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_Passport" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T2_Passport_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_Passport" id="T2_Passport_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality != 'A00' ? '1' : '0'"
                  data-required-msg="請上傳護照" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>護照</span>Copy of Passport<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_Passport"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_Transcript"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_Transcript_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_Transcript" id="T2_Transcript_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality != 'A00' ? '1' : '0'"
                  data-required-msg="請上傳歷年成績單" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>歷年成績單</span>Full transcript of current school<span
                  style="color: red;" v-if="Apply.AId != -1 && Apply.T2_Transcript"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_StudentCard"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_StudentCard_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_StudentCard" id="T2_StudentCard_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality != 'A00' ? '1' : '0'"
                  data-required-msg="請上傳學生證" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>學生證</span>Photocopy of student card<span
                  style="color: red;" v-if="Apply.AId != -1 && Apply.T2_StudentCard"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_SelfIntroduction"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_SelfIntroduction_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_SelfIntroduction" id="T2_SelfIntroduction_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality != 'A00' ? '1' : '0'"
                  data-required-msg="請上傳自傳" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>自傳</span>Self-Introduction<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_SelfIntroduction"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_StudyPlan"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_StudyPlan_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_StudyPlan" id="T2_StudyPlan_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality != 'A00' ? '1' : '0'"
                  data-required-msg="請上傳學習計畫書" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>學習計畫書</span>Study Plan<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_StudyPlan"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_CNProficiencyCert"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_CNProficiencyCert_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_CNProficiencyCert" id="T2_CNProficiencyCert_data" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span>中文能力證明(如有)</span>Chinese Proficiency Certificate (if available)<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_CNProficiencyCert"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full other_box">
              <div class="custom_file">
                <input type="file" id="T2_Other" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T2_Other_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_Other" id="T2_Other_data" />
                <div class="upload">上傳</div>
              </div>
              <input type="text" class="form-control" v-model="Apply.T2_OtherURL" aria-describedby="" placeholder="請填寫網址">
              <label for=""><span>其它補充文件(如作品集)</span>Other requirement documents (Ex. Portfolio)<span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_Other"> (已上傳, 可更新)</span></label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 2 && Apply.Nationality == 'A00'"
            v-show="Step == 2 || Step == 3">
            <h2>上傳申請文件<span></span></h2>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_C_Recommend"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_C_Recommend_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_C_Recommend" id="T2_C_Recommend_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality == 'A00' ? '1' : '0'"
                  data-required-msg="請上傳臺灣藝術大學姊妹校交換生推薦表(.pdf)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>臺灣藝術大學姊妹校交換生推薦表(.pdf)</span>Nomination Form for Exchange
                Study (.pdf)<span style="color: red;" v-if="Apply.AId != -1 && Apply.T2_C_Recommend"> (已上傳,
                  可更新)</span></label>
              <div class="download_box">
                <a class="download" href="files/nomination_form_for_exchange.xlsx">範本下載</a>
                <a class="download" href="files/nomination_form_for_exchange_en.xlsx" style="margin-left: 5px;">範本下載</a>
              </div>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_C_Passport"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_C_Passport_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_C_Passport" id="T2_C_Passport_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality == 'A00' ? '1' : '0'"
                  data-required-msg="請上傳有效期限內居民身分證彩色正反面清晰掃描檔" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>有效期限內居民身分證彩色正反面清晰掃描檔</span><span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_C_Passport"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_C_Access" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T2_C_Access_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_C_Access" id="T2_C_Access_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality == 'A00' ? '1' : '0'"
                  data-required-msg="請上傳大陸地區人民入出臺灣地區申請書(.docx)" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>大陸地區人民入出臺灣地區申請書(.docx)</span><span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_C_Access"> (已上傳, 可更新)</span></label>
              <div class="download_box">
                <a class="download" href="files/附件3 入出台灣地區申請書.docx">範本下載</a>
              </div>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_C_StudyCert"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_C_StudyCert_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_C_StudyCert" id="T2_C_StudyCert_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality == 'A00' ? '1' : '0'"
                  data-required-msg="請上傳在學證明" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>在學證明</span><span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_C_StudyCert"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_C_Transcript"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_C_Transcript_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_C_Transcript" id="T2_C_Transcript_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality == 'A00' ? '1' : '0'"
                  data-required-msg="請上傳在校歷年成績單" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>在校歷年成績單</span><span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_C_Transcript"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_C_SelfIntroduction"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_C_SelfIntroduction_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_C_SelfIntroduction" id="T2_C_SelfIntroduction_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality == 'A00' ? '1' : '0'"
                  data-required-msg="請上傳自傳" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>自傳</span><span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_C_SelfIntroduction"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T2_C_StudyPlan"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T2_C_StudyPlan_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_C_StudyPlan" id="T2_C_StudyPlan_data"
                  :data-required="Step == 2 && ApplyType == 2 && Apply.Nationality == 'A00' ? '1' : '0'"
                  data-required-msg="請上傳學習計畫書" />
                <div class="upload">上傳</div>
              </div>
              <label for=""><span><span class="required">*</span>學習計畫書</span><span style="color: red;"
                  v-if="Apply.AId != -1 && Apply.T2_C_StudyPlan"> (已上傳, 可更新)</span></label>
            </div>
            <div class="form-group full other_box">
              <div class="custom_file">
                <input type="file" id="T2_C_Other" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T2_C_Other_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T2_C_Other" id="T2_C_Other_data" />
                <div class="upload">上傳</div>
              </div>
              <input type="text" class="form-control" v-model="Apply.T2_C_OtherURL" aria-describedby=""
                placeholder="請填寫網址">
              <label for=""><span>其它補充文件(如作品集)</span><span style="color: red;" v-if="Apply.AId != -1 && Apply.T2_C_Other">
                  (已上傳, 可更新)</span></label>
            </div>
          </div>
          <div class="box review" v-if="Apply && ApplyType == 3" v-show="Step == 2 || Step == 3">
            <h2>上傳申請文件<span></span></h2>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T3_Recommend_1"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T3_Recommend_1_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T3_Recommend_1" id="T3_Recommend_1_data"
                  :data-required="Step == 2 && ApplyType == 3 ? '1' : '0'" data-required-msg="請上傳第一封教授推薦函" />
                <div class="upload">上傳</div>
              </div>
              <label for="">
                <span><span class="required">*</span>第一封教授推薦函<span style="color: red;"
                    v-if="Apply.AId != -1 && Apply.T3_Recommend_1"> (已上傳, 可更新)</span></span>
                <div class="instructions" v-show="ApplyData.Event.T3_Recommend_1 != -1">
                  <a class="download" href="javascript:void(0)"
                    @click="gotoPostByPId(ApplyData.Event.T3_Recommend_1)">說明</a>
                </div>
              </label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T3_Recommend_2"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T3_Recommend_2_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T3_Recommend_2" id="T3_Recommend_2_data"
                  :data-required="Step == 2 && ApplyType == 3 ? '1' : '0'" data-required-msg="請上傳第二封教授推薦函" />
                <div class="upload">上傳</div>
              </div>
              <label for="">
                <span><span class="required">*</span>第二封教授推薦函<span style="color: red;"
                    v-if="Apply.AId != -1 && Apply.T3_Recommend_2"> (已上傳, 可更新)</span></span>
                <div class="instructions" v-show="ApplyData.Event.T3_Recommend_2 != -1">
                  <a class="download" href="javascript:void(0)"
                    @click="gotoPostByPId(ApplyData.Event.T3_Recommend_2)">說明</a>
                </div>
              </label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T3_SelfIntroduction"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T3_SelfIntroduction_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T3_SelfIntroduction" id="T3_SelfIntroduction_data"
                  :data-required="Step == 2 && ApplyType == 3 ? '1' : '0'" data-required-msg="請上傳履歷及自傳" />
                <div class="upload">上傳</div>
              </div>
              <label for="">
                <span><span class="required">*</span>履歷及自傳<span style="color: red;"
                    v-if="Apply.AId != -1 && Apply.T3_SelfIntroduction"> (已上傳, 可更新)</span></span>
                <div class="instructions" v-show="ApplyData.Event.T3_SelfIntroduction != -1">
                  <a class="download" href="javascript:void(0)"
                    @click="gotoPostByPId(ApplyData.Event.T3_SelfIntroduction)">說明</a>
                </div>
              </label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T3_StudyPlan"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T3_StudyPlan_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T3_StudyPlan" id="T3_StudyPlan_data"
                  :data-required="Step == 2 && ApplyType == 3 ? '1' : '0'" data-required-msg="請上傳讀書/研究計畫" />
                <div class="upload">上傳</div>
              </div>
              <label for="">
                <span><span class="required">*</span>讀書/研究計畫<span style="color: red;"
                    v-if="Apply.AId != -1 && Apply.T3_StudyPlan"> (已上傳, 可更新)</span></span>
                <div class="instructions" v-show="ApplyData.Event.T3_StudyPlan != -1">
                  <a class="download" href="javascript:void(0)"
                    @click="gotoPostByPId(ApplyData.Event.T3_StudyPlan)">說明</a>
                </div>
              </label>
            </div>
            <div class="form-group full">
              <input type="" class="form-control" v-model="Apply.T3_Other"
                :data-required="Step == 2 && ApplyType == 3 ? '1' : '0'" data-required-msg="請填寫網址" aria-describedby=""
                placeholder="請填寫網址">
              <label for="">
                <span><span class="required">*</span>電子檔作品集線上雲端硬碟連結</span>
                <div class="instructions" v-show="ApplyData.Event.T3_Other != -1">
                  <a class="download" href="javascript:void(0)" @click="gotoPostByPId(ApplyData.Event.T3_Other)">說明</a>
                </div>
              </label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T3_Transcript"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T3_Transcript_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T3_Transcript" id="T3_Transcript_data" />
                <div class="upload">上傳</div>
              </div>
              <label for="">
                <span><span class="required">*</span>中(英文)成績單<span style="color: red;"
                    v-if="Apply.AId != -1 && Apply.T3_Transcript"> (已上傳, 可更新)</span></span>
                <div class="instructions" v-show="ApplyData.Event.T3_Transcript != -1">
                  <a class="download" href="javascript:void(0)"
                    @click="gotoPostByPId(ApplyData.Event.T3_Transcript)">說明</a>
                </div>
              </label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T3_LangCert" accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt"
                  @change="checkFiles">
                <input type="hidden" id="T3_LangCert_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T3_LangCert" id="T3_LangCert_data" />
                <div class="upload">上傳</div>
              </div>
              <label for="">
                <span>語言檢定證明<span style="color: red;"
                    v-if="Apply.AId != -1 && Apply.T3_LangCert"> (已上傳, 可更新)</span></span>
                <div class="instructions" v-show="ApplyData.Event.T3_LangCert != -1">
                  <a class="download" href="javascript:void(0)" @click="gotoPostByPId(ApplyData.Event.T3_LangCert)">說明</a>
                </div>
              </label>
            </div>
            <div class="form-group full">
              <div class="custom_file">
                <input type="file" id="T3_Award_Cert"
                  accept=".bmp,.jpg,.gif,.tif,.png,.gif,.jpeg,.odt,.doc,.docx,.pdf,.txt" @change="checkFiles">
                <input type="hidden" id="T3_Award_Cert_name" data-namgtag="1" />
                <input type="hidden" v-model="Apply.T3_Award_Cert" id="T3_Award_Cert_data" />
                <div class="upload">上傳</div>
              </div>
              <label for="">
                <span>得獎證明<span style="color: red;"
                    v-if="Apply.AId != -1 && Apply.T3_Award_Cert"> (已上傳, 可更新)</span></span>
                <div class="instructions" v-show="ApplyData.Event.T3_Award_Cert != -1">
                  <a class="download" href="javascript:void(0)"
                    @click="gotoPostByPId(ApplyData.Event.T3_Award_Cert)">說明</a>
                </div>
              </label>
            </div>
          </div>
          <div class="btn_box" v-if="Apply" v-show="Step == 2">
            <button @click="gotoStep(1)">上一步</button>
            <button @click="gotoStep(3)">下一步</button>
          </div>
          <div class="btn_box" v-if="Apply" v-show="Step == 3">
            <button @click="gotoStep(2)">上一步</button>
            <button @click="gotoStep(4)">送件</button>
          </div>
          <div class="box done" v-if="Apply" v-show="Step == 4">
            <h2>報名完成</h2>
            <div class="text_box">
              <p>您的申請已送出，申請編號為:{{ AId }}，審核進度請前往會員中心進行確認</p>
            </div>
            <div class="btn_box">
              <router-link v-if="Apply.ApplyType == 3" :to="{ name: 'ApplyPrint', params: { AId: AId } }"
                tag="button">列印</router-link>
              <router-link :to="{ name: 'Home', params: {} }" tag="button">回首頁</router-link>
              <router-link :to="{ name: 'Member', params: {} }" tag="button">查詢進度</router-link>
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>
<script>
import Head from "@/components/Head.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
  },
  en: {
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

import AES from "@/utils/aes.js";
import Validate from "@/utils/validate.js";

import Select2 from 'v-select2-component';

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);

export default {
  i18n,
  name: "Apply",
  components: {
    Head,
    Footer,
    Select2
  },
  data() {
    return {
      ApplyInfoData: null,
      ApplyData: null,
      Apply: null,
      local: "zh_tw",
      ApplyType: -1,
      Step: 0,
      AId: -1,
    };
  },
  beforeCreate: function () {
  },
  created: function () {
    this.ApplyType = this.$route.params.ApplyType;
    Vue.axios
      .get(
        this.$store.state.src +
        this.$store.state.subDirectory +
        "/Html/ApplyInit",
        {
          params: { ApplyType: this.ApplyType },
        }
      )
      .then((response) => {
        this.ApplyInfoData = response.data;
        if (this.ApplyInfoData.Error) {
          let that = this;
          Swal.fire({
            title: "錯誤",
            text: this.ApplyInfoData.Error,
            icon: "error",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              that.$router.push({
                name: "Home",
              });
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    if ($cookies.isKey("is_site_lang")) {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = $cookies.get("is_site_lang");
    } else {
      i18n.locale = $cookies.get("is_site_lang");
      this.local = "zh_tw";
    }
  },
  updated: function () {
  },
  methods: {
    gotoStep(step) {
      if (this.Step == 0 && step == 1) {
        if (!$cookies.isKey("is_login_token")) {
          Swal.fire({
            title: "請先登入帳號",
            icon: "info",
            allowOutsideClick: false,
            heightAuto: false,
            willClose: () => {
              this.$router.push({
                name: "Login",
              });
            },
          });
          return;
        }
        Vue.axios
          .get(
            this.$store.state.src +
            this.$store.state.subDirectory +
            "/Html/Apply",
            {
              params: { AId: this.$route.params.AId, ApplyType: this.ApplyType, token: $cookies.get("is_login_token") },
            }
          )
          .then((response) => {
            this.ApplyData = response.data;
            if (this.ApplyData.Error) {
              let that = this;
              Swal.fire({
                title: "錯誤",
                text: this.ApplyData.Error,
                icon: "error",
                allowOutsideClick: false,
                heightAuto: false,
                willClose: () => {
                  that.$router.push({
                    name: "Home",
                  });
                },
              });
            } else {
              this.Apply = this.ApplyData.Apply;
              if (this.Apply != -1 && this.Apply.ApplyType != this.ApplyType) {
                let that = this;
                Swal.fire({
                  title: "錯誤",
                  text: "申請表類型不符合",
                  icon: "error",
                  allowOutsideClick: false,
                  confirmButtonText: "確定",
                  heightAuto: false,
                  willClose: () => {
                    that.$router.push({
                      name: "Home",
                    });
                  },
                });
              }
              if (this.Apply.Birthday) {
                this.Apply.Birthday = this.convertDatetimeString(
                  this.Apply.Birthday,
                  "YYYY-MM-DD"
                );
              } else {
                this.Apply.Birthday = "";
              }
              if (this.Apply.T1_StudyDurationStart) {
                this.Apply.T1_StudyDurationStart = this.convertDatetimeString(
                  this.Apply.T1_StudyDurationStart,
                  "YYYY-MM"
                );
              } else {
                this.Apply.T1_StudyDurationStart = "";
              }
              if (this.Apply.T1_StudyDurationEnd) {
                this.Apply.T1_StudyDurationEnd = this.convertDatetimeString(
                  this.Apply.T1_StudyDurationEnd,
                  "YYYY-MM"
                );
              } else {
                this.Apply.T1_StudyDurationEnd = "";
              }
              if (this.Apply.T3_Birthday) {
                this.Apply.T3_Birthday = this.convertDatetimeString(
                  this.Apply.T3_Birthday,
                  "YYYY-MM-DD"
                );
              } else {
                this.Apply.T3_Birthday = "";
              }
              if (this.Apply.T3_StudyDurationStart) {
                this.Apply.T3_StudyDurationStart = this.convertDatetimeString(
                  this.Apply.T3_StudyDurationStart,
                  "YYYY-MM"
                );
              } else {
                this.Apply.T3_StudyDurationStart = "";
              }
              if (this.Apply.T3_StudyDurationEnd) {
                this.Apply.T3_StudyDurationEnd = this.convertDatetimeString(
                  this.Apply.T3_StudyDurationEnd,
                  "YYYY-MM"
                );
              } else {
                this.Apply.T3_StudyDurationEnd = "";
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
        this.Step = 1;
        $('html,body').animate({ scrollTop: 0 }, 1000);
      } else if (this.Step == 1 && step == 2) {
        var hasError = false;
        $("[data-required='1']").each(function () {
          var input = $(this);
          if (!input.val() || input.val() == '-1') {
            input.focus();
            Swal.fire({
              title: "錯誤",
              text: input.data("required-msg"),
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            hasError = true;
            return false;
          } else if (input.attr("type") == "email" &&
            !Validate.reg_email(input.val())) {
            Swal.fire({
              title: "錯誤",
              text: "Email格式錯誤",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            hasError = true;
            return false;
          }
        });
        if (hasError) {
          return;
        }
        if (this.ApplyType != 3) {
          if (!this.$refs.Phone.phoneObject.valid ||
            !this.$refs.EmergencyPhone.phoneObject.valid) {
            Swal.fire({
              title: "錯誤",
              text: "請填寫電話號碼",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            return;
          }
          if (!this.Apply.Program) {
            Swal.fire({
              title: "錯誤",
              text: "請選擇系所",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            return;
          }
          if (!this.Apply.Nationality) {
            Swal.fire({
              title: "錯誤",
              text: "請選擇國籍",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            return;
          }
        } else {
          if (!this.$refs.T3_Phone.phoneObject.valid) {
            Swal.fire({
              title: "錯誤",
              text: "請填寫電話號碼",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            return;
          }
          if (!this.Apply.T3_ApplySchool) {
            Swal.fire({
              title: "錯誤",
              text: "請選擇申請學校",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            return;
          }
        }
        if (this.ApplyType == 1 && !this.Apply.T1_CountryOfSchool) {
          Swal.fire({
            title: "錯誤",
            text: "請選擇學校國家",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
          return;
        }
        this.Step = 2;
        $('html,body').animate({ scrollTop: 0 }, 1000);
      } else if (this.Step == 2 && step == 3) {
        var hasError = false;
        $("[data-required='1']").each(function () {
          var input = $(this);
          if (!input.val() || input.val() == '-1') {
            input.focus();
            Swal.fire({
              title: "錯誤",
              text: input.data("required-msg"),
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
            hasError = true;
            return false;
          }
        });
        if (hasError) {
          return;
        }
        this.Step = 3;
        $('html,body').animate({ scrollTop: 0 }, 1000);
      } else if (this.Step == 3 && step == 4) {
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        // 儲存檔案名稱
        var AttachmentNames = "";
        $("[data-namgtag='1']").each(function () {
          if (AttachmentNames.length > 0) { AttachmentNames += ", "; } else { AttachmentNames += "{"; }
          var input = $(this);
          AttachmentNames += '"' + input.attr("id").replace("_name", "") + '": "' + input.val() + '"';
        });
        AttachmentNames += "}";
        let that = this;
        $.ajax({
          url:
            that.$store.state.src +
            that.$store.state.subDirectory +
            "/Html/Apply",
          type: "post",
          data: {
            ApplyJson: JSON.stringify(this.Apply),
            AttachmentNames: AttachmentNames,
            token: $cookies.get("is_login_token")
          },
          dataType: "json",
          error: function () { },
          success: function (res) {
            if (res.ok) {
              // Swal.fire({
              //   title: "申請已送出",
              //   icon: "success",
              //   allowOutsideClick: false,
              //   showConfirmButton: false,
              //   heightAuto: false,
              //   timer: 1500,
              //   willClose: () => {
              //     that.$router.push({
              //       name: "Member",
              //     });
              //   },
              // });
              that.AId = res.AId;
              that.Step = 4;
              Swal.close();
              $('html,body').animate({ scrollTop: 0 }, 1000);
            } else if (res.error) {
              Swal.fire({
                title: "錯誤",
                text: res.error,
                icon: "error",
                allowOutsideClick: false,
                confirmButtonText: "確定",
                heightAuto: false,
              });
            }
          }
        });
      } else {
        this.Step = step;
        $('html,body').animate({ scrollTop: 0 }, 0);
      }

      if (this.Step == 3) {
        $(".review").css("pointer-events", "none");
      } else {
        $(".review").css("pointer-events", "auto");
      }
    },
    setImage(id, rst) {
      $("#" + id + "_preview").attr("src", rst);
      $("#" + id + "_preview").css('opacity', '1');
      $("#" + id + "_data").val(rst);
      Swal.close();
    },
    previewFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 10485760) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過10MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            $("#" + id).val("");
            if (id == "Photo") {
              that.Apply.Photo = reader.result;
            }
            that.setImage(id, reader.result);
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理圖片過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    checkFiles(event) {
      let that = this;
      var id = event.target.id;
      var e = event.target;
      if (e.files.length != 0) {
        // 顯示請稍候
        Swal.fire({
          title: "請稍候...",
          allowOutsideClick: false,
          showConfirmButton: false,
          heightAuto: false,
          willOpen: function () {
            Swal.showLoading();
          },
        });
        var file = e.files[0];
        // 不進行壓縮的情況要檢查大小
        if (file.size > 10485760) {
          Swal.fire({
            title: "錯誤",
            text: "檔案大小超過10MB",
            icon: "error",
            allowOutsideClick: false,
            confirmButtonText: "確定",
            heightAuto: false,
          });
        } else {
          var reader = new FileReader();
          reader.onloadend = function () {
            if (id == "T1_Passport") {
              that.Apply.T1_Passport = reader.result;
            } else if (id == "T1_HighCert") {
              that.Apply.T1_HighCert = reader.result;
            } else if (id == "T1_Transcript") {
              that.Apply.T1_Transcript = reader.result;
            } else if (id == "T1_BirthCertificate") {
              that.Apply.T1_BirthCertificate = reader.result;
            } else if (id == "T1_ProofOfFinancial") {
              that.Apply.T1_ProofOfFinancial = reader.result;
            } else if (id == "T1_SelfIntroduction") {
              that.Apply.T1_SelfIntroduction = reader.result;
            } else if (id == "T1_StudyPlan") {
              that.Apply.T1_StudyPlan = reader.result;
            } else if (id == "T1_TOCFL") {
              that.Apply.T1_TOCFL = reader.result;
            } else if (id == "T1_Declaration") {
              that.Apply.T1_Declaration = reader.result;
            } else if (id == "T1_PersonalDataAgree") {
              that.Apply.T1_PersonalDataAgree = reader.result;
            } else if (id == "T1_Other") {
              that.Apply.T1_Other = reader.result;
            } else if (id == "T1_Scholarship") {
              that.Apply.T1_Scholarship = reader.result;
            } else if (id == "T2_Recommend") {
              that.Apply.T2_Recommend = reader.result;
            } else if (id == "T2_Passport") {
              that.Apply.T2_Passport = reader.result;
            } else if (id == "T2_Transcript") {
              that.Apply.T2_Transcript = reader.result;
            } else if (id == "T2_StudentCard") {
              that.Apply.T2_StudentCard = reader.result;
            } else if (id == "T2_SelfIntroduction") {
              that.Apply.T2_SelfIntroduction = reader.result;
            } else if (id == "T2_StudyPlan") {
              that.Apply.T2_StudyPlan = reader.result;
            } else if (id == "T2_CNProficiencyCert") {
              that.Apply.T2_CNProficiencyCert = reader.result;
            } else if (id == "T2_Other") {
              that.Apply.T2_Other = reader.result;
            } else if (id == "T2_C_Recommend") {
              that.Apply.T2_C_Recommend = reader.result;
            } else if (id == "T2_C_Passport") {
              that.Apply.T2_C_Passport = reader.result;
            } else if (id == "T2_C_Access") {
              that.Apply.T2_C_Access = reader.result;
            } else if (id == "T2_C_StudyCert") {
              that.Apply.T2_C_StudyCert = reader.result;
            } else if (id == "T2_C_Transcript") {
              that.Apply.T2_C_Transcript = reader.result;
            } else if (id == "T2_C_SelfIntroduction") {
              that.Apply.T2_C_SelfIntroduction = reader.result;
            } else if (id == "T2_C_StudyPlan") {
              that.Apply.T2_C_StudyPlan = reader.result;
            } else if (id == "T2_C_Other") {
              that.Apply.T2_C_Other = reader.result;
            } else if (id == "T3_Recommend_1") {
              that.Apply.T3_Recommend_1 = reader.result;
            } else if (id == "T3_Recommend_2") {
              that.Apply.T3_Recommend_2 = reader.result;
            } else if (id == "T3_SelfIntroduction") {
              that.Apply.T3_SelfIntroduction = reader.result;
            } else if (id == "T3_StudyPlan") {
              that.Apply.T3_StudyPlan = reader.result;
            } else if (id == "T3_Transcript") {
              that.Apply.T3_Transcript = reader.result;
            } else if (id == "T3_LangCert") {
              that.Apply.T3_LangCert = reader.result;
            } else if (id == "T3_Award_Cert") {
              that.Apply.T3_Award_Cert = reader.result;
            }
            $("#" + id + "_data").val(reader.result);
            $("#" + id + "_name").val(file.name);
            //$("#" + id).val('');
            Swal.close();
          };
          reader.onerror = function (error) {
            $("#" + id).val("");
            Swal.fire({
              title: "錯誤",
              html:
                "處理檔案過程發生錯誤:<br />" +
                error +
                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "確定",
              heightAuto: false,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    },
    convertDatetimeString(time, format) {
      var date = new Date(
        time.replace(
          /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
          "$4:$5:$6 $2/$3/$1"
        )
      );
      return moment(date).format(format);
    },
    gotoPostByPId: function (PId) {
      const routeData = this.$router.resolve({
        name: "Post",
        params: { PId: PId },
      });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

